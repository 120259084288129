var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0 ma-0 grey lighten-4"},[_c('v-row',{attrs:{"align":"end","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"width":"300","hide-details":!_vm.templateCreated,"messages":"Page refresh may be required for rendering the new templates","items":_vm.tmplNamesWithAux,"label":"Template name"},scopedSlots:_vm._u([(_vm.templateCreated)?{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{staticStyle:{"color":"darkorange","font-weight":"bold"}},[_vm._v(_vm._s(message))])]}}:null],null,true),model:{value:(_vm.tmplName),callback:function ($$v) {_vm.tmplName=$$v},expression:"tmplName"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","icon":"","disabled":!_vm.prjName},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.dialogCreateTemplate.show()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus-box-multiple-outline")])],1)]}}])},[_c('span',[_vm._v("Create New Template...")])])],1)],1)],1),_c('tutti-dialog',{ref:"dialogCreateTemplate",attrs:{"maxWidth":"400","title":"Create New Template","allowEnter":true,"actions":[
            {
                label: 'Create',
                color: 'indigo darken-1',
                text: true,
                disableByRule: true,
                onclick: _vm.createTemplate
            },
            {
                label: 'Cancel',
                color: 'grey darken-1',
                text: true
            }
        ]},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-text-field',{attrs:{"filled":"","autofocus":"","prepend-icon":"mdi-pencil","label":"Enter Template Name","rules":[_vm.rules.required, _vm.rules.alphanumeric]},model:{value:(_vm.newTemplateName),callback:function ($$v) {_vm.newTemplateName=$$v},expression:"newTemplateName"}}),_c('v-autocomplete',{attrs:{"dense":"","filled":"","items":_vm.presetsList,"prepend-icon":"mdi-shape","label":"Choose Preset Template","rules":[_vm.rules.required]},model:{value:(_vm.newTemplatePreset),callback:function ($$v) {_vm.newTemplatePreset=$$v},expression:"newTemplatePreset"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }